import React from "react"
import IndexPage from './index.js'

const ElectionsOnlineVotingToolPage = () => (
  <IndexPage
    title="Create Elections with nVotes' Online Voting Tool"
    description="Grant legitimacy to your election with our auditable online voting tool"
    showCallToAction={true}
  />
)

export default ElectionsOnlineVotingToolPage
